<div class="footer-area" *ngIf="hiddenNav">

	<div *ngIf="isMantenimientos; else defaultFooter" id="footer-mantenimientos">
		<div class="contenedor-mantenimientos-mobile" id="footer-mobile">
			<div class="logo" id="logo-grupo-bancolombia">
				<bc-logo size="170px" width="5rem" name="grupo-bancolombia-negative" [ariaHidden]="false"
					alt="logo"></bc-logo>
			</div>
			<p id="fecha-actual">Fecha actual: {{ currentDate | date:'dd MMM yyyy' }}</p>
			<p id="ultimo-ingreso">Último ingreso: {{ lastLoginDate }} - {{ lastLoginHour }} </p>
			<p id="direccion-ip">IP: {{ ipClient }} </p>
		</div>

		<div class="contenedor-mantenimientos-desktop" id="footer-desktop">
			<div class="logo-copyright" id="logo-bancolombia-horizontal">
				<bc-logo size="170px" width="5rem" name="bancolombia-horizontal-negative" [ariaHidden]="false"
					alt="logo"></bc-logo>
				<p class="copyrigth" style="padding-left: 20px;">Copyright © 2024 Grupo Bancolombia.</p>
			</div>
			<div class="ip-ultima-visita">
				<p id="direccion-ip">Dirección IP: {{ ipClient }} </p>
				<p id="ultimo-ingreso">Última visita: {{ lastLoginDate }} - {{ lastLoginHour }} </p>
			</div>
		</div>
	</div>

	<ng-template #defaultFooter>
		<div *ngIf="!dark" class="copyright-area">
			<div class="contenedor-info">
				<div class="row mobile">
					<div class="bc-col contenedor-logo">
						<div>
							<div class="logo-mobile">
								<bc-logo size="170px" width="5rem" name="grupo-bancolombia-negative"
									[ariaHidden]="false" alt="logo"></bc-logo>
							</div>
							<div class="logo-web">
								<bc-logo size="170px" width="5rem" name="bancolombia-horizontal-negative"
									[ariaHidden]="false" alt="logo"></bc-logo>
							</div>
						</div>
						<div class="copyright-area-dark">
							<span class="copyright">
								Copyright © 2024 Grupo Bancolombia
							</span>
						</div>
						@if (isFirma){
						<div class="contenedorVigilado">
							<bc-logo class="rotarLogo" size="20px" width="5rem" name="logo-vigilado-negative"
								[ariaHidden]="false" alt="logo"></bc-logo>
						</div>
						}
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</div>