import {
	INegotiationInfo,
	initialStateNegotiation
} from "../states/negotiation-info.state";
import { ENegotiationInfo, NegotiationAction } from "../actions/negotiation-info.action";

export function negotiationInfoReducer(
	state = initialStateNegotiation,
	action: NegotiationAction
): INegotiationInfo {
	switch (action.type) {
		case ENegotiationInfo.LOAD_NEGOTIATIONINFO_SUCCESS: 
			return loadNegotiationInfoSuccess(state, action);
		case ENegotiationInfo.LOAD_NEGOTIATIONINFO_FAIL:
			return loadNegotiationInfoFail(state);
		case ENegotiationInfo.UPDATE_DOCUMENT_STATE: 
		debugger
			return updateDocumentState(state, action)
		default:
			return state;
	}
}

	function loadNegotiationInfoSuccess(state, action) {
		return {
			...state,
			data: action.payload
		}
	}

	function loadNegotiationInfoFail(state) {
		return {
			...state
		}
	}

	function updateDocumentState(state, action){
		const { numberConsolidate, nameDocument, statusDocument} =
			action.payload;

		const updatedAlternatives = state.data.alternatives.map(
			(alternatives) => {
				return {
					...alternatives,
					dataAlternative: updateDataAlternative(
						alternatives.dataAlternative,
						numberConsolidate,
						nameDocument,
						statusDocument
					)
				};
			}
		);

		return {
			...state,
			data: {
				...state.data,
				alternatives: updatedAlternatives
			}
		}
	}

	function updateDataAlternative(
		dataAlternative, 
		numberConsolidate, 
		nameDocument, 
		statusDocument
	) {
		return dataAlternative.map((data) => {
			if (
				data.numberConsolidate ===
					numberConsolidate &&
				data.documents
			) {
				return {
					...data,
					documents: updateDocuments(
						data.documents, 
						nameDocument, 
						statusDocument
					)
				};
			}
			return data;
		})
	}

	function updateDocuments(
		documents, 
		nameDocument, 
		statusDocument
	) {
		return documents.map((item) => {
			if (
				item.nameDocument ===
				nameDocument
			) {
				return {
					...item,
					statusDocument:
						statusDocument
				};
			}
			return item;
		})
	}
